






import ClientSiteDepartments from '../../components/ClientSiteDepartments.vue';
import { defineComponent } from 'vue-demi';

export default defineComponent({
  components: { ClientSiteDepartments },
  name: 'ClientLocationsPage',
});
